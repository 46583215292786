angular
  .module('public')
  .controller('NavbarController', NavbarController)

NavbarController['$inject'] = ['$element']
function NavbarController ($element) {
  const ctrl = this

  ctrl.toggle = toggle

  updateStickyNavbar()
  window.addEventListener('scroll', updateStickyNavbar)

  function toggle (key) {
    if (ctrl.activeMenu === key) {
      ctrl.activeMenu = null
    } else {
      ctrl.activeMenu = key
    }
  }

  function updateStickyNavbar () {
    if (window.pageYOffset > 40) {
      $element.addClass('sticky')
    } else {
      $element.removeClass('sticky')
    }
  }
}
