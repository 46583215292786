angular
  .module('public')
  .config(['$httpProvider', config])

function config ($httpProvider) {
  $httpProvider.defaults.paramSerializer = '$httpParamSerializerJQLike'
  $httpProvider.defaults.headers.common['Accept']           = 'application/json'
  $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
  $httpProvider.defaults.headers.common['X-CSRF-Token']     = angular.element(document.querySelector('meta[name=csrf-token]')).attr('content')
}
