angular
  .module('public')
  .controller('ContactController', ContactController)

ContactController['$inject'] = ['$rootScope', '$http', '$timeout']
function ContactController ($rootScope, $http, $timeout) {
  const ctrl = this
  const now  = new Date()
  ctrl.submit = submit

  $rootScope.setContactSubject = setContactSubject

  $timeout(function () {
    const input = document.querySelector('input[aria-label*="Ne pas remplir"]')
    angular.element(input).attr('tabindex', '-1')

    const element = angular.element(input).parent()
    element.addClass('honey')
  })

  function submit (subject) {
    if (new Date() - now < 5000) return
    if (angular.isFunction($rootScope.cancelPopin)) $rootScope.cancelPopin()

    // To fight against bots,
    // we reject comments with only one word.
    if (ctrl.contact.comment && ctrl.contact.comment.match(/^\s*[^\s]+\s*$/)) {
      ctrl.form.comment.$setValidity('words', false)
    } else if (ctrl.form.comment) {
      ctrl.form.comment.$setValidity('words', true)
    }

    if (angular.isObject(ctrl.contact.options)) {
      ctrl.contact.options = Object.values(ctrl.contact.options)
      ctrl.contact.options = ctrl.contact.options.filter(elem => { return elem !== false })
    }

    if (ctrl.form.$invalid) return
    $http.post('/contact', {
      subject: (subject || ctrl.subject),
      contact: ctrl.contact
    }).then(function () {
      ctrl.completed = true
    })
  }

  function setContactSubject (subject) {
    ctrl.subject = subject
  }
}
