const links = document.querySelectorAll('a[href^="#"]')

if (links && links.length) init()

function init () {
  Array.prototype.forEach.call(links, function (a) {
    const anchor = (a.getAttribute('href') || a.href).slice(1)

    a.addEventListener('click', function (event) {
      event.preventDefault()
      const offset = (anchor === 'top') ? 122 : 57
      scrollToAnchor(anchor, 0 - offset)
    })
  })
}

// Functions
// Inspired by https://jsfiddle.net/brettdewoody/y65G5/
// ----------------------------------------------------------------------------
function scrollToAnchor (id, offset) {
  const target  = elementPosition(id) + (offset || 0)
  let current   = currentPosition()
  let distance  = Math.abs(target - current)

  if (distance < 100) {
    window.scrollTo(0, target)
    return
  }

  if (distance > 600) {
    current  = (target > current) ? target - 600 : target + 600
    distance = 600
    window.scrollTo(0, current)
  }

  let speed = Math.round(distance / 100)
  if (speed >= 20) speed = 20

  const step = Math.round(distance / 25)
  let leapY = target > current ? current + step : current - step
  let timer = 0
  let i

  if (target > current) {
    for (i = current; i < target; i += step) {
      setTimeout(function () {
        window.scrollTo(0, leapY)
      }, timer * speed)
      leapY += step

      if (leapY > target) leapY = target
      timer++
    }
    return
  }

  for (i = current; i > target; i -= step) {
    setTimeout(function () {
      window.scrollTo(0, leapY)
    }, timer * speed)
    leapY -= step

    if (leapY < target) leapY = target
    timer++
  }
}

function currentPosition () {
  if (window.pageYOffset) {
    return window.pageYOffset
  } else {
    return document.documentElement.scrollTop
  }
}

function elementPosition (id) {
  let element = document.getElementById(id)
  let offset  = element.offsetTop

  while (element.offsetParent && element.offsetParent !== document.body) {
    element = element.offsetParent
    offset += element.offsetTop
  }

  return offset
}
